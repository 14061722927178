
import { defineComponent } from 'vue';
import { mapState, mapWritableState } from 'pinia';
import { IAction } from '../../../entities/action';
import { getIntegration, getActions } from '@/services/steamcord/actions';
import { useActionStore } from '@/store/actionStore';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import { formatDate } from '@/util/formatDate';
import { getRandomInt } from '@/util/getRandomInt';
import TableRow from '@/components/dashboard/TableRow.vue';

export default defineComponent({
  components: {
    TableRow,
  },
  async beforeRouteEnter(to, from, next) {
    const { currentOrg } = useOrgStore();
    if (!currentOrg) {
      next({ name: 'Internal Integration Error' });
      return;
    }

    const integration = await getIntegration(currentOrg.id, to.params.id.toString());
    const actionStore = useActionStore();
    if (!integration) {
      actionStore.integration = undefined;
      next({ name: 'Not Found' });
      return;
    }

    actionStore.integration = integration;
    await actionStore.getActionDefinitions();

    const actions = await getActions(currentOrg.id, integration.id.toString());

    if (actions?.length === 0) {
      next({ name: 'New Action', params: { id: integration.id }, replace: true });
      return;
    }

    next((vm) => {
      (vm as any).setActions(actions);
    });
  },
  computed: {
    formattedDate(): string | undefined {
      if (!this.integration) {
        return undefined;
      }

      return formatDate(this.integration.createdDate as Date);
    },
    ...mapState(useOrgStore, ['currentOrg']),
    ...mapState(useActionStore, ['integration']),
    ...mapWritableState(useMainStore, ['loading']),
  },
  data() {
    return {
      actions: undefined as IAction[] | undefined,
      copySuccess: false,
    };
  },
  methods: {
    getRandomInt(min: number, max: number) {
      return getRandomInt(min, max);
    },
    setActions(actions: IAction[]) {
      this.actions = actions;
    },
  },
  mounted() {
    this.loading = false;
  },
});
