import axios from 'axios';
import { defineStore } from 'pinia';
import { IActionDefinition } from '../entities/actionDefinition';
import { IIntegration } from '../entities/integration';

export const useActionStore = defineStore('actions', {
  actions: {
    async getActionDefinitions() {
      const { data: definitions } = await axios.get(
        `/actions/definitions?IntegrationType=${this.integration?.type}`,
      );

      this.actionDefinitions = definitions;
    },
  },
  state() {
    return {
      actionDefinitions: undefined as IActionDefinition[] | undefined,
      integration: undefined as IIntegration | undefined,
    };
  },
});
