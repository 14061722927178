import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass({ 'table-row': true, 'table-row--clickable': _ctx.clickable })
  }, [
    _createElementVNode("td", null, [
      _renderSlot(_ctx.$slots, "text")
    ]),
    _renderSlot(_ctx.$slots, "buttons")
  ], 2))
}